import React from "react"
import classNames from "classnames"

import ProjectTitle from "./ProjectTitle"
import * as styles from "./ProjectArticle.module.scss"

const ProjectArticle = ({ title, children, article = false }) => (
    <div className={classNames(styles.Article)}>
        <ProjectTitle article={article}>{title}</ProjectTitle>
        <div className={classNames(styles.ArticleBody, { [styles.noTopPadding]: article })}>{children}</div>
    </div>
)

export default ProjectArticle
