import React, { useRef, useEffect, useState, useMemo } from "react"; // eslint-disable-line no-unused-vars

import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { AnimatePresence, motion } from "framer-motion";
import ReactDOM from "react-dom";
import * as styles from "./Lightbox.module.scss";
import classNames from "classnames";
import { useLightboxContext } from "./LightboxProvider";

const LIGHTBOX_EL_ID = "rca-lightbox";

const LightboxWrapper = ({ open, children }) => {
  // const { colorMode } = useRCAContext()
  const { media, mediaIndex } = useLightboxContext();
  const domNodeRef = useRef();
  const [hasEl, setHasEl] = useState(false);

  useEffect(() => {
    domNodeRef.current = document.getElementById(LIGHTBOX_EL_ID);

    if (!domNodeRef.current) {
      const el = document.createElement("div");
      el.id = LIGHTBOX_EL_ID;
      document.body.appendChild(el);
      domNodeRef.current = el;
    }

    setHasEl(true);
  }, []);

  useEffect(() => {
    if (open) {
      disableBodyScroll(domNodeRef.current, { reserveScrollBarGap: true });
    } else {
      enableBodyScroll(domNodeRef.current);
    }
  }, [open]);

  const isDark = useMemo(() => {
    return media[mediaIndex] ? media[mediaIndex].dark : false;
  }, [media, mediaIndex]);

  if (!hasEl) {
    return null;
  }

  return ReactDOM.createPortal(
    <AnimatePresence initial={false}>
      {open && (
        <motion.div
          key="modal"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={classNames(styles.wrapper, { "dark-mode": isDark })}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>,
    domNodeRef.current,
  );
};

export { LightboxWrapper };
