import React, { useRef, useCallback, useEffect } from "react"

import { GatsbyImage } from "gatsby-plugin-image"
import { useLightboxContext } from "../Common/Lightbox/LightboxProvider"

// Styles
import * as styles from "./ProjectGallery.module.scss"

// Components

const ProjectGallery = ({ title, introduction, gallery }) => {
    const { appendMedia, openMedia } = useLightboxContext()
    const lightboxIndex = gallery.map((x) => useRef(null))
    const dark = false

    useEffect(() => {
        gallery.forEach((element, index) => {
            lightboxIndex[index].current = appendMedia(element, element.title, element.caption, dark)
        })
    }, [gallery])

    const onOpenLightbox = useCallback(
        (index) => {
            // openMedia(lightboxIndex.current)
            openMedia(index)
        },
        [openMedia]
    )

    return (
        <div>
            <h3>{title}</h3>
            <div>
                <p>{introduction}</p>
            </div>
            <div className={styles.galleryGrid}>
                {gallery !== null &&
                    gallery.length > 0 &&
                    gallery.map((item, index) => {
                        return (
                            <div className={styles.imageItem} key={`image-${index}`}>
                                <div
                                    onClick={() => {
                                        onOpenLightbox(index)
                                    }}
                                    className={styles.gridItem}
                                >
                                    <GatsbyImage image={item.gatsbyImage} alt={item.altText || item.title} />
                                </div>
                            </div>
                        )
                    })}
            </div>
        </div>
    )
}

export default ProjectGallery
