import React from "react"

const ShapeDiver = ({ urlUri }) => {
  const styles = { overflowX: "hidden", overflowY: "hidden", borderWidth: "0" }
  return (
    <div>
      <iframe
        title={urlUri}
        id="sdv-iframe"
        width="100%"
        height="480px"
        src={`${urlUri}&brandedMode=false`}
        referrerPolicy="origin"
        allowFullScreen
        scrolling="no"
        style={styles}
      >
        <p>Your browser does not support iframes.</p>
      </iframe>
    </div>
  )
}

export default ShapeDiver
