import React, { useCallback } from "react"

const Issuu = ({ urlUri }) => {
  const urlParts = urlUri.split("/")
  const docsIndex = urlParts.indexOf("docs")
  const userName = urlParts[docsIndex - 1]
  const docName = urlParts[docsIndex + 1]

  const onIssuuLoad = useCallback(() => {
    window.dispatchEvent(new Event("tile-loaded"))
  }, [])

  return (
    <div>
      <iframe
        allowFullScreen
        allow="fullscreen"
        src={`//e.issuu.com/embed.html?d=${docName}&u=${userName}`}
        title={urlUri}
        style={{ border: "none", width: "100%", height: "675px" }}
        onLoad={onIssuuLoad}
      >
        <p>Your browser does not support iframes.</p>
      </iframe>
    </div>
  )
}

export default Issuu
