import React from "react"
import withPreview, { getContent } from "../apollo/preview"
import { getNewsPreview as PREVIEW_QUERY } from "../preview-queries/newsPreview"

// Data
import { graphql } from "gatsby"

// Component
import Layout from "../components/Global/Layout"
import Main from "../components/Shared/Main"
import ProjectArticle from "../components/Project/ProjectArticle"
import ProjectIntroduction from "../components/Project/ProjectIntroduction"
import ProjectFlexibleContent from "../components/Project/ProjectFlexibleContent"
import ProjectAuthorDate from "../components/Project/ProjectAuthorDate"

import Content from "../components/Shared/Content"
import CentredContentWithSidebar from "../components/Shared/CentredContentWithSidebar"
import ArticleHeroImage from "../components/Shared/ArticleHeroImage"
import HeroAreaSmall from "../components/Shared/HeroAreaSmall"
import ProjectTitle from "../components/Project/ProjectTitle"
import ArticleMeta from "../components/Shared/ArticleMeta"
import Sidebar from "../components/Shared/Sidebar"
import CTA from "../components/Shared/CTA"
import PersonCTA from "../components/Shared/PersonCTA"
import ShareLinks from "../components/Shared/ShareLinks"

import { LightboxContainer } from "../components/Common/Lightbox/LightboxProvider"

import { venueForPage } from "../helpers/venueForPage"
import SEOSettings from "../components/Shared/SEOSettings"

const ProjectArticleTemplate = (props) => {
    const page = getContent(props, "projectarticle")

    // const introduction = page.projectArticleDetails.introduction
    const flexibleContent = page.projectArticleflexibleContent.blocks

    // const postAuthor = (page) => {
    //     if (page.news_fields.author) return page.news_fields.author.title

    //     if (!page.author.firstName || page.author.firstName.length === 0) return null

    //     return `${page.author.firstName} ${page.author.lastName}`
    // }

    const postDate = (page) => {
        if (page.news_fields.articleDate) return page.news_fields.articleDate

        const dateTime = new Date(page.date)

        return dateTime.toLocaleString("en-GB", { day: "numeric", month: "long", year: "numeric" })
    }

    return (
        <Layout venue={venueForPage({ page: page, menus: props.data.menus })} currentLocation={props.path} currentTemplate="templateProject" title={page.title}>
            <LightboxContainer>
                {/* <SEOSettings entity={page} /> */}
                <HeroAreaSmall
                    title="Projects"
                    backlink="/projects"
                    image={{ sourceUrl: page.image.image.sourceUrl, title: `${page.title} image` }}
                    archive={page.projectArticleDetails.archive}
                />

                <Main>
                    <CentredContentWithSidebar>
                        <Content>
                            <ProjectArticle title={page.title} article={true}>
                                {/* <ProjectIntroduction>
                                <div dangerouslySetInnerHTML={{ __html: introduction }}></div>
                            </ProjectIntroduction> */}
                                {/* Project Flexible Content Fields */}
                                <ProjectAuthorDate {...page.projectArticleDetails} />
                                <ProjectFlexibleContent flexibleContent={flexibleContent} />
                            </ProjectArticle>
                        </Content>

                        {/* <Sidebar>
                        {page.sidebar_calls.sidebarCtas && page.sidebar_calls.sidebarCtas.map((cta) => <CTA key={cta.id} {...cta} />)}

                        {page.person_ctas.people && page.person_ctas.people.map((person) => <PersonCTA key={person.id} {...person} />)}
                    </Sidebar> */}
                    </CentredContentWithSidebar>
                </Main>
            </LightboxContainer>
        </Layout>
    )
}

export const pageQuery = graphql`
    query ProjectArticleQuery($databaseId: Int!) {
        menus: allWpMenu {
            results: nodes {
                ...PageMenusNode
            }
        }

        projectarticle: wpProjectArticle(databaseId: { eq: $databaseId }) {
            id
            title
            publishToLive {
                publishToLive
            }
            content
            date
            link
            excerpt
            image: hero_fields {
                image {
                    altText
                    sourceUrl
                    title
                }
            }
            author {
                node {
                    firstName
                    lastName
                }
            }
            projectArticleDetails {
                archive
                author
                date
            }
            projectArticleflexibleContent {
                blocks {
                    ... on WpProjectArticle_Projectarticleflexiblecontent_Blocks_Text {
                        textBlock
                    }
                    ... on WpProjectArticle_Projectarticleflexiblecontent_Blocks_Partners {
                        partnersInformation {
                            title
                            introduction
                            partners {
                                title
                                link
                                logo {
                                    altText
                                    sourceUrl
                                    gatsbyImage(width: 250, layout: FULL_WIDTH)
                                }
                            }
                        }
                    }
                    ... on WpProjectArticle_Projectarticleflexiblecontent_Blocks_Videos {
                        videos {
                            title
                            video
                            caption
                        }
                    }
                    ... on WpProjectArticle_Projectarticleflexiblecontent_Blocks_Audio {
                        audio
                        title
                    }
                    ... on WpProjectArticle_Projectarticleflexiblecontent_Blocks_Images {
                        images {
                            title
                            image {
                                altText
                                sourceUrl
                                gatsbyImage(width: 960, layout: FULL_WIDTH)
                            }
                            caption
                        }
                    }
                    ... on WpProjectArticle_Projectarticleflexiblecontent_Blocks_Downloads {
                        downloads {
                            title
                            introduction
                            downloads {
                                title
                                description
                                file {
                                    mediaItemUrl
                                    localFile {
                                        publicURL
                                    }
                                }
                            }
                        }
                    }
                    ... on WpProjectArticle_Projectarticleflexiblecontent_Blocks_Gallery {
                        galleryBlock {
                            title
                            introduction
                            gallery {
                                altText
                                sourceUrl
                                gatsbyImage(width: 960, layout: FULL_WIDTH)
                                title
                            }
                        }
                    }
                    ... on WpProjectArticle_Projectarticleflexiblecontent_Blocks_Quote {
                        quoteBlock {
                            quote
                            author
                        }
                    }
                }
            }
        }
    }
`

export default withPreview({ preview: PREVIEW_QUERY })(ProjectArticleTemplate)
