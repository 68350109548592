import React from "react"

import classNames from "classnames"
import ReactPlayer from "react-player"

import { media } from "../HostedEmbed.module.scss"

// For Platform options see:
// https://www.npmjs.com/package/react-player

const Youtube = ({ urlUri }) => {
  return (
    <div className={classNames(media)}>
      <ReactPlayer
        url={urlUri}
        config={{
          youtube: {
            playerVars: { showinfo: 1, controls: true },
            embedOptions: {},
          },
        }}
        playing={false}
        width="100%"
        height="100%"
      />
    </div>
  )
}

export default Youtube
