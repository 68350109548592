import React from "react"

import ProjectEventLink from "./ProjectEventLink"
import ProjectDownloads from "./ProjectDownloads"
import ProjectPartnersInformation from "./ProjectPartnersInformation"
import ProjectQuote from "./ProjectQuote"
import ProjectGallery from "./ProjectGallery"
import { HostedEmbed } from "../Common/HostedEmbed"
import { GatsbyImage } from "gatsby-plugin-image"

import * as styles from "./ProjectFlexibleContent.module.scss"

const ProjectFlexibleContent = ({ flexibleContent }) => {
    return (
        <div>
            {flexibleContent &&
                flexibleContent?.map((item, index) => {
                    return (
                        <div key={`block-${index}`}>
                            {/* textBlock */}
                            {item.textBlock && <div dangerouslySetInnerHTML={{ __html: item.textBlock }}></div>}

                            {/* Project Video */}
                            {item.videos &&
                                item.videos.map((video, index) => {
                                    return (
                                        <div key={`video-${index}`}>
                                            {video.title && <h3>{video.title}</h3>}
                                            {video.video && <HostedEmbed mediaUrl={video.video} />}
                                            {video.caption && <div className={styles.caption}>{video.caption}</div>}
                                        </div>
                                    )
                                })}

                            {/* Project Audio */}
                            {item.audio && (
                                <div>
                                    {item.title && <h3>{item.title}</h3>}
                                    {item.audio && <HostedEmbed mediaUrl={item.audio} />}
                                </div>
                            )}

                            {/* Images */}
                            {item.images &&
                                item.images.map((item, index) => {
                                    return (
                                        <div key={`image-${index}`} className={styles.flexibleContentImage}>
                                            {item.title && <h3>{item.title}</h3>}
                                            <GatsbyImage image={item.image.gatsbyImage} alt={item.image.altText} />
                                            {item.caption && <div className={styles.caption}>{item.caption}</div>}
                                        </div>
                                    )
                                })}

                            {/* Link to Live Event */}
                            {item.eventBlock && <div>{<ProjectEventLink {...item.eventBlock} />}</div>}

                            {/* Downloads */}
                            {item.downloads && <ProjectDownloads {...item.downloads} />}

                            {/* Partners */}
                            {item.partnersInformation && <ProjectPartnersInformation {...item.partnersInformation} />}

                            {/* Quote */}
                            {item.quoteBlock && <ProjectQuote {...item.quoteBlock} />}

                            {/* Gallery */}
                            {item.galleryBlock && <ProjectGallery {...item.galleryBlock} />}
                        </div>
                    )
                })}
        </div>
    )
}

export default ProjectFlexibleContent
