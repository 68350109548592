import React from "react"

import { GatsbyImage } from "gatsby-plugin-image"

// Styles
import * as styles from "./ProjectEventLink.module.scss"

// Components

const ProjectEventLink = ({ image, introduction, link, title }) => {
    return (
        <div className={styles.linkBlock}>
            <div className={styles.leftImage}>
                <a href={link?.url}>
                    <GatsbyImage image={image.gatsbyImage} alt={image.altText} />
                </a>
            </div>
            <div className={styles.rhs}>
                <div>
                    <ul>
                        <li>
                            <a href={link?.url}>{title}</a>
                        </li>
                    </ul>
                </div>
                <div className={styles.description} dangerouslySetInnerHTML={{ __html: introduction }}></div>
            </div>
        </div>
    )
}

export default ProjectEventLink
