import React from "react"

import { GatsbyImage } from "gatsby-plugin-image"

// Styles
import * as styles from "./ProjectDownloads.module.scss"

// Components

const ProjectDownloads = ({ title, introduction, downloads }) => {
    return (
        <div>
            <h3>{title}</h3>
            <div dangerouslySetInnerHTML={{ __html: introduction }}></div>
            <div className={styles.downloadGrid}>
                {downloads !== null &&
                    downloads.length > 0 &&
                    downloads.map((download, index) => {
                        return (
                            <div className={styles.downloadItem} key={`download-${index}`}>
                                <div className={styles.title}>
                                    <a href={download.file.mediaItemUrl}>{download.title}</a>
                                </div>
                                <div className={styles.description}>{download.description}</div>
                            </div>
                        )
                    })}
            </div>
        </div>
    )
}

export default ProjectDownloads
