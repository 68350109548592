import React from "react"

import * as styles from "./ProjectQuote.module.scss"

const ProjectQuote = ({ quote, author }) => {
    return (
        <div className={styles.quoteBlock}>
            <div>“{quote}”</div>
            <div className={styles.author}>{author}</div>
        </div>
    )
}

export default ProjectQuote
