import React from "react"

import classNames from "classnames"
import ReactPlayer from "react-player"

import { media } from "../HostedEmbed.module.scss"

// For Platform options see:
// https://www.npmjs.com/package/react-player

const Streamable = ({ urlUri }) => {
  return (
    <div className={classNames(media)}>
      <ReactPlayer
        url={urlUri}
        config={{}}
        controls
        playing={false}
        width="100%"
        height="100%"
      />
    </div>
  )
}

export default Streamable
