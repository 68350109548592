import React, { useEffect } from "react"

const Sirv = ({ urlUri }) => {
  useEffect(() => {
    const hasScript = document.getElementById("sirvscript")

    const sirvLoad = () => {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          window.dispatchEvent(new Event("tile-loaded"))
        })
      })
    }

    if (!hasScript) {
      const script = document.createElement("script")

      script.id = "sirvscript"
      script.src = "https://scripts.sirv.com/sirv.js"
      script.async = true
      script.onload = () => {
        sirvLoad()
      }

      document.body.appendChild(script)
    } else {
      setTimeout(() => {
        sirvLoad()
      }, 500)
    }
  }, [])

  return (
    <>
      <div style={{ width: "auto", height: "611px" }}>
        <div className="Sirv" data-src={urlUri}></div>
      </div>
    </>
  )
}

export default Sirv
