import React from "react"

import * as styles from "./ProjectAuthorDate.module.scss"

const ProjectAuthorDate = ({ archive, author, date }) => {
    return (
        <div className={styles.authorDate}>
            {author && (
                <span>
                    <span className={styles.author}>By {author}&nbsp;</span>
                </span>
            )}
            {date && (
                <span>
                    <span className={styles.date}>{date}</span>
                </span>
            )}
        </div>
    )
}

export default ProjectAuthorDate
