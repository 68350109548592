import React, { useEffect, useState } from "react"

import classNames from "classnames"
import ReactPlayer from "react-player"

import { media, portrait } from "../HostedEmbed.module.scss"

// For Platform options see:
// https://www.npmjs.com/package/react-player

const Vimeo = ({ urlUri }) => {
    const [pt, setPt] = useState("56.25%")
    const [ptNumber, setPtNumber] = useState(56.25)

    useEffect(() => {
        fetch(`https://vimeo.com/api/oembed.json?url=${urlUri}`)
            .then((resp) => resp.json())
            .then(({ width, height }) => {
                if (width && height) {
                    setPtNumber(Math.ceil((height / width) * 100))
                    setPt(`${Math.ceil((height / width) * 100)}%`)
                }
            })
        return () => {}
    }, [])

    // console.log("urlUri ", urlUri)

    return (
        <div className={ptNumber > 100 && portrait}>
            <div className={classNames(media)} style={{ "--embed-aspect": `${pt}` }}>
                <ReactPlayer
                    url={urlUri}
                    config={{
                        vimeo: {
                            playerOptions: {
                                byline: true,
                                color: "#000099",
                            },
                        },
                    }}
                    controls={true}
                    playing={false}
                    width="100%"
                    height="100%"
                    onReady={() => this.player.seekTo(200)}
                />
            </div>
        </div>
    )
}

export default Vimeo
