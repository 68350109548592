import React from "react"

import classNames from "classnames"
import ReactPlayer from "react-player"

import { media } from "../HostedEmbed.module.scss"

const Wistia = ({ urlUri }) => {
  // https://home.wistia.com/medias/e4a27b971d
  // https://fast.wistia.net/embed/iframe/qld34r7mia
  // https://platform-3.wistia.com/medias/qld34r7mia

  let srcUrl = urlUri

  // Allow for url pattern that includes '/embed/iframe/'
  if (urlUri.includes("/embed/iframe/")) {
    const id = urlUri.substring(urlUri.lastIndexOf("/") + 1)
    srcUrl = `https://home.wistia.com/medias/${id}`
  }

  return (
    <div className={classNames(media)}>
      <ReactPlayer
        url={srcUrl}
        config={{
          wistia: {
            options: {
              fullscreenButton: true,
              playerColor: "#000099",
              playBar: true,
            },
          },
        }}
        controls
        playing={false}
        width="100%"
        height="100%"
      />
    </div>
  )
}

export default Wistia
