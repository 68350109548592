// extracted by mini-css-extract-plugin
export var arrow = "Lightbox-module--arrow--1e882";
export var arrowIcon = "Lightbox-module--arrow-icon--4686c";
export var arrowLeft = "Lightbox-module--arrow-left--5d1e1";
export var arrowRight = "Lightbox-module--arrow-right--b549d";
export var close = "Lightbox-module--close--9b8a0";
export var inner = "Lightbox-module--inner--07c14";
export var innerContent = "Lightbox-module--inner-content--c7c5b";
export var lightbox = "Lightbox-module--lightbox--576d5";
export var lightboxFooter = "Lightbox-module--lightbox-footer--b5d79";
export var lightboxFooterCaption = "Lightbox-module--lightbox-footer-caption--aad6a";
export var lightboxFooterInner = "Lightbox-module--lightbox-footer-inner--70e55";
export var lightboxFooterNumber = "Lightbox-module--lightbox-footer-number--bf5b8";
export var lightboxFooterTitle = "Lightbox-module--lightbox-footer-title--f2d6a";
export var lightboxHeader = "Lightbox-module--lightbox-header--4dcb2";
export var lightboxMiddle = "Lightbox-module--lightbox-middle--054d7";
export var lightboxSlider = "Lightbox-module--lightbox-slider--d47cb";
export var media = "Lightbox-module--media--19897";
export var mediaContainer = "Lightbox-module--media-container--f1a8e";
export var mediaImage = "Lightbox-module--media-image--8bbbf";
export var mediaSanity = "Lightbox-module--media-sanity--496dc";
export var sanityContainer = "Lightbox-module--sanity-container--bc5e8";
export var wrapper = "Lightbox-module--wrapper--193b7";