import React from "react"

import { GatsbyImage } from "gatsby-plugin-image"

// Styles
import * as styles from "./ProjectPartnersInformation.module.scss"

// Components

const ProjectPartnersInformation = ({ title, introduction, partners }) => {
    return (
        <div>
            <h3>{title}</h3>
            <div dangerouslySetInnerHTML={{ __html: introduction }}></div>
            <div className={styles.partnerGrid}>
                {partners !== null &&
                    partners.length > 0 &&
                    partners.map((partner, index) => {
                        return (
                            <div className={styles.partnerItem} key={`download-${index}`}>
                                <div>
                                    <a href={partner.link}>
                                        <GatsbyImage image={partner.logo.gatsbyImage} alt={partner.logo.altText} />
                                    </a>
                                </div>
                                <div className={styles.linkContainer}>
                                    <a href={partner.link}>{partner.title}</a>
                                </div>
                            </div>
                        )
                    })}
            </div>
        </div>
    )
}

export default ProjectPartnersInformation
