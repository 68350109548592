import React from "react"
import classNames from "classnames"

import * as styles from "./ProjectTitle.module.scss"

const ProjectTitle = ({ children, article = false }) => (
    <div className={classNames(styles.ProjectTitle, { [styles.article]: article })}>
        <h1 className={styles.title}>{children}</h1>
    </div>
)

export default ProjectTitle
