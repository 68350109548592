import React, { useState } from "react"
import { useSpring, animated } from "react-spring"

import { GatsbyImage } from "gatsby-plugin-image"

import classNames from "classnames"
import ReactPlayer from "react-player"

import { mp4, placeholder, mp4Portait } from "../HostedEmbed.module.scss"

// For Platform options see:
// https://www.npmjs.com/package/react-player

const Mp4Player = ({ urlUri, placeholderImage, isPortrait }) => {
  const [ready, setReady] = useState(false)

  const props = useSpring({
    to: { opacity: 1, height: "auto" },
    from: { opacity: 0, height: 0 },
    config: { duration: 1500 },
  })

  return (
    <>
      {ready === false && placeholderImage && (
        <div className={placeholder}>
          <GatsbyImage
            image={placeholderImage?.image?.asset?.gatsbyImageData}
            alt={placeholderImage?.altText || ""}
            loading="eager"
          />
        </div>
      )}
      <animated.div style={props}>
        <div className={classNames(mp4, { [mp4Portait]: isPortrait === true })}>
          <ReactPlayer
            url={urlUri}
            config={{}}
            playing={true}
            // controls
            loop
            width="100%"
            height="100%"
            muted={true}
            onReady={() => setReady(true)}
            onError={() => setReady(false)}
          />
        </div>
      </animated.div>
    </>
  )
}

export default Mp4Player
