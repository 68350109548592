import React from "react"

import classNames from "classnames"

import { media } from "../HostedEmbed.module.scss"

const InDesign = ({ urlUri }) => {
  return (
    <div className={classNames(media)} style={{ "--embed-aspect": "50%" }}>
      <iframe
        // allowFullScreen
        allow="fullscreen"
        src={urlUri}
        title={urlUri}
      >
        <p>Your browser does not support iframes.</p>
      </iframe>
    </div>
  )
}

export default InDesign
